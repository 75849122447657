<template>
  <div>
    <div style="font-size: 18px" class="mb-1">Dienstversion: {{ service.version }}
      <span class="text-uppercase text-secondary small">{{ service.version_channel }}</span>
    </div>
    <div class="ml-3 text-bold">Änderungen:</div>
    <div v-for="change in changeList" :key="change" class="ml-3">
      - {{ change }}
    </div>
    <div class="ml-3 text-secondary">Erschienen am {{ service.created_at | datetime }}</div>
  </div>
</template>

<script>
export default {
  name: "Service",
  props: ['service'],
  computed: {
    changeList() {
      return this.service.description.split(';')
    }
  }
}
</script>

<style lang="scss">
</style>
